@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?61979672');
  src: url('../font/fontello.eot?61979672#iefix') format('embedded-opentype'),
  url('../font/fontello.woff2?61979672') format('woff2'),
  url('../font/fontello.woff?61979672') format('woff'),
  url('../font/fontello.ttf?61979672') format('truetype'),
  url('../font/fontello.svg?61979672#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?61979672#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  font-size: 160%;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  /* width: 1em; */
  /* margin-right: .2em; */
  /* opacity: .8; */
}

.icon-impostazioni:before { content: '\e800'; } /* '' */
.icon-utent1:before { content: '\e801'; } /* '' */
.icon-dashboard:before { content: '\e802'; } /* '' */
.icon-password:before { content: '\e803'; } /* '' */
.icon-contratti:before { content: '\e804'; } /* '' */
.icon-rate:before { content: '\e805'; } /* '' */
.icon-corpo:before { content: '\e808'; } /* '' */
.icon-iniziativa:before { content: '\e809'; } /* '' */
.icon-aziende:before { content: '\e80a'; } /* '' */
.icon-proprietà:before { content: '\e80b'; } /* '' */
.icon-categoria_1:before { content: '\e80c'; } /* '' */
.icon-bilancio:before { content: '\e80d'; } /* '' */
.icon-costi:before { content: '\e80e'; } /* '' */
.icon-categoria_2:before { content: '\e80f'; } /* '' */
.icon-iva:before { content: '\e810'; } /* '' */
.icon-listino_commerciale:before { content: '\e811'; } /* '' */
.icon-tassazione:before { content: '\e812'; } /* '' */
.icon-imu:before { content: '\e813'; } /* '' */
.icon-istat:before { content: '\e814'; } /* '' */
.icon-ricavi:before { content: '\e815'; } /* '' */
.icon-flussi_economici:before { content: '\e817'; } /* '' */
.icon-immobili:before { content: '\e818'; } /* '' */
.icon-home:before { content: '\e819'; } /* '' */
.icon-impianti:before { content: '\e81a'; } /* '' */
.icon-aggiungi:before { content: '\e81b'; } /* '' */
.icon-modifica:before { content: '\e81c'; } /* '' */
.icon-elimina:before { content: '\e81d'; } /* '' */
